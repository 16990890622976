.services_w{
    padding-top: 2rem;
}

.services_w h2{
    font-size: 25px;
}

.services__box{
    margin-right: 150px;
    margin-left: 150px;
    padding-top: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.services__container{
    width: var(--container-width-lg);
    margin: 0 auto;
}

.services__box_2{
    margin-right: 150px;
    margin-left: 150px;
    margin-top: 120px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.portfolio__item{
    background: linear-gradient(rgba(125, 5, 1, 0.8) 40%,transparent 100%);
    padding: 1.5rem;
    margin: -30px;
    border-radius: 2rem;
    text-align: center;
    transition: var(--transition);
    margin-bottom: 100px;
}

.portfolio__item:hover{
  border-color: var(--color-primary-variant);  
  background: transparent;
} 

.portfolio__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item-cta a{
    text-decoration: none;
}

.portfolio__item h3{
    margin: 1.2rem 0 2rem;
}
.portfolio__item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

/* =============== MEDIA QUERIES (Medium devices) ============= */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .services__box{
        grid-template-columns: repeat(2, 1fr);
        gap: 5rem;
    }
    .services_w{
        margin-top: 0;
    }
    .portfolio__item{
        margin-bottom: 100px;
    }
}

/* =============== MEDIA QUERIES (Medium devices) ============= */
@media only screen and (max-width: 767px) { 
    .services__box{
        width: 60%;
    grid-template-columns: 1fr;
    margin: auto;
    gap: 1rem;
}
}