.container.contact__container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
    margin-top: 2rem;
}

.contact__options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}



.getToKnow{
    font-size: 25px;

}
.AboutMe{
    font-size: 20px;
}

.contact__option{
    /* background: linear-gradient(rgba(161, 212, 248, 0.6) 20%,transparent 100%); */
    background: linear-gradient(rgba(125, 5, 1, 0.8) 40%,transparent 80%);
    padding: 0.7rem;
    margin-left: -100px;
    border-radius: 1.2rem;
    text-align: center;
    transition: var(--transition);
}

.contact__option:hover{
    background: rgb(125, 5, 1,0.5);
    border-color: var(--color-primary-variant);
}



.contact__option-icon{
    font-size: 1.5rem;
}

.contact__option h5{
    margin: 0;
    padding: 10px;
} 

.contact__option h4{
    margin: 0;
    padding: 0;
} 

.contact__option a{
    margin-top: 0.7rem;
    text-decoration:none;
    display: inline-block;
    font-size: 0.8rem;
}


form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    resize: none;
    color: var(--color-white);
    border: 2px solid var(--color-primary-variant);
}

/* =============== MEDIA QUERIES (Medium devices) ============= */
@media screen and (max-width: 1024px) {
   .container.contact__container{
    grid-template-columns: 1fr;
    gap: 2rem;
   }
   .contact__option{
   margin: auto;
   width: 80%;
   }
   .btn-primary {
    margin: auto;
    }
   .contact{
    margin-top: 5rem;
    margin-bottom: 5rem;
}
}

/* =============== MEDIA QUERIES (Medium devices) ============= */
@media screen and (max-width: 600px) { 
    .container.contact__container{
        width: var(--container-width-sm);
       }
       .contact{
        margin-top: 0;
        margin-bottom: 0;
    }
    .contact__option{
        width: 60%;
        margin: auto;
       }

    input, textarea{
        width: 60%;
        margin: auto;
    }

    .btn-primary {
        margin: auto;
    }
}