.experience__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    width: var(--container-width-lg);
    margin: 0 auto;
    margin-bottom: 5rem;
}

.experience_w{
    padding-top: 1rem;
}
h3{
    text-align: center;
    margin: 30px;
}

.experience_w h2{
    font-size: 25px;
}
.experience__container > div {
    background: linear-gradient(rgba(125, 5, 1, 0.8) 40%,transparent 100%);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    transition: var(--transition);
}

.experience__container > div:hover {
    background: rgb(125, 5, 1,0.5);
    border-color: var(--color-primary-variant);
    cursor: default;
} 

.experience__container > div h5 {
    text-align: center;
    margin-bottom: 2px;
    color: var(--color-white);
}

.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 1rem;
}

.experience__details {
     display: flex;
     gap: 1rem; 
}



.experience__details-icon {
    margin-top: 25px;
    color: var(--color-primary);
}

/* =============== MEDIA QUERIES (Medium devices) ============= */
@media screen and (max-width: 1024px) {
    .experience__container{
        grid-template-columns: 1fr;
    }
    .experience__container>div{
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience_w{
        margin-top: 5rem;
    }

    .experience__content{
        padding: 1rem;
    }
}

/* =============== MEDIA QUERIES (Medium devices) ============= */
@media screen and (max-width: 600px) { 
   .experience__container>div{
    width: 60%;
    padding: 2rem;
    margin: 0 auto;
    width: var(--container-width-md);
}

.experience__container{
    margin-left: 10%;
}
.experience__content {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 5rem;
    width: var(--container-width-sm);
}
}