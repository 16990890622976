footer{
    background: linear-gradient(var(--color-primary),transparent);
    padding: 1rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 5rem;
}

footer a{
    color: var(--color-bg);
}

.footer__logo{
    font-size: 20px;
    font-weight: 50;
    margin-bottom: 10px;
    padding: 0;
    display: inline-block;
    text-decoration:none;
    
}

.permalinks{
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 30px;
}

.footer__socials{
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 15px ;
}

.footer__socials a{
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footer__socials a:hover{
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.footer__copyright {
    margin-bottom: 5rem;
    color: var(--color-bg);
}

.footer__tag{
    text-decoration:none;
}
li{
    list-style-type: none;
    
}


 
 /* =============== MEDIA QUERIES (Medium devices) ============= */
 @media screen and (max-width: 600px) { 
    .permalinks{
        flex-direction: column;
        gap: 1.5rem;
    }
    .footer__socials{
        margin-bottom: 2.6rem;
    }
 }